import { CustomField } from "./CustomMetadataField";

/**
 * A helper to convert the data from a form with a metadata array into the
 * metadata object needed by the GoCardless API.
 */
export const getCleanedMetadata = (
  metadata: CustomField[]
): Record<string, string> | undefined => {
  const cleanedMetadata = metadata?.reduce(
    (acc: Record<string, string>, { name, value }) => {
      const trimmedName = name?.trim();
      if (trimmedName) {
        acc[trimmedName] = value?.trim() || "";
      }
      return acc;
    },
    {}
  );
  return cleanedMetadata
    ? Object.keys(cleanedMetadata).length
      ? cleanedMetadata
      : {}
    : {};
};
