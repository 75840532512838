import {
  Field,
  FormFieldStatus,
  Hint,
  Input,
  Label,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { FieldArrayWithId, useFormContext } from "react-hook-form";
import { getInputErrorStatus } from "src/utils/inputValidation";

import { FormWithMetadata } from "./CustomMetadataField";

interface MetadataValueFieldProps {
  field: FieldArrayWithId<FormWithMetadata, "metadata", "id">;
  index: number;
}

export const MetadataValueField: React.FC<MetadataValueFieldProps> = ({
  index,
  field,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormWithMetadata>();

  return (
    <Field>
      <Label htmlFor={`metadata.${index}.value`}>
        <Trans id="open-mandate.manual-creation.custom-value-field">
          Value
        </Trans>
      </Label>
      <Input
        {...register(`metadata.${index}.value`)}
        id={`metadata.${index}.value`}
        data-testid={`metadata.${index}.value`}
        type="text"
        defaultValue={field.value}
        status={getInputErrorStatus(!!errors.metadata?.[index]?.value)}
      />
      {errors.metadata?.[index]?.value ? (
        <Hint status={FormFieldStatus.Danger} data-testid="value-field-error">
          <Trans id="open-mandate.manual-creation.error-value-required">
            Value is required
          </Trans>
        </Hint>
      ) : null}
    </Field>
  );
};
