import { Box, XYGrid } from "@gocardless/flux-react";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { MetadataKeyField } from "./MetadataKeyField";
import { MetadataValueField } from "./MetadataValueField";
import { MetadataFieldWrapper } from "./MetadataFieldWrapper";
import { AddAndRemoveFieldRow } from "./AddAndRemoveFieldRow";

export interface CustomField {
  name?: string;
  value?: string;
}

export interface FormWithMetadata {
  metadata: CustomField[];
}

interface CustomMetadataFormFieldProps {
  canRemoveAll?: boolean;
  description?: string | React.ReactNode;
  hideMetadataCheckbox?: boolean;
  hideMetadataKeySelection?: boolean;
  isInitiallyOpen?: boolean;
  tooltip?: string | React.ReactNode;
  isRestricted?: boolean;
}

export const CustomMetadataFormField: React.FC<
  CustomMetadataFormFieldProps
> = ({
  canRemoveAll = false,
  description,
  hideMetadataCheckbox = false,
  hideMetadataKeySelection = false,
  isInitiallyOpen = false,
  tooltip,
  isRestricted,
}) => {
  const { control, watch } = useFormContext<FormWithMetadata>();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "metadata",
  });
  const customFieldsData = watch("metadata");
  const [duplicateField, setDuplicateField] = useState<string | undefined>();
  const [metadataChecked, setMetadataChecked] = useState(
    hideMetadataCheckbox || isInitiallyOpen
  );

  const checkMetadataValuesForDuplicates = () => {
    const names = customFieldsData.map((data) => data.name);
    const duplicates = names.filter(
      (item, index) => index !== names.indexOf(item)
    );

    if (duplicates.length > 0) {
      setDuplicateField(duplicates[0]);
    } else {
      setDuplicateField(undefined);
    }
  };

  const clickMetadataCheckbox = (metadataVal: boolean) => {
    setMetadataChecked(metadataVal);
    if (!metadataVal) {
      fields.forEach(() => {
        remove();
      });
    } else {
      if (fields.length === 0) {
        append({ name: "", value: "" });
      }
    }
  };

  const requiresMinimumFieldToBeAppended =
    hideMetadataCheckbox && fields.length === 0;
  useEffect(() => {
    if (requiresMinimumFieldToBeAppended) {
      append({ name: "", value: "" });
    }
  }, [requiresMinimumFieldToBeAppended, append]);

  const removeMetadata = (index: number) => {
    if (fields.length === 1) {
      replace([{ name: "", value: "" }]);
    } else {
      remove(index);
    }
    checkMetadataValuesForDuplicates();
  };

  return (
    <MetadataFieldWrapper
      hideMetadataCheckbox={hideMetadataCheckbox}
      metadataChecked={metadataChecked}
      clickMetadataCheckbox={clickMetadataCheckbox}
      tooltip={tooltip}
      description={description}
      duplicateField={duplicateField}
      isRestricted={isRestricted}
    >
      {fields.map((field, index, array) => (
        <Box key={field.id} spaceAbove={2}>
          <XYGrid
            templateColumns={["1fr", null, null, "1fr 1fr"]}
            rowGap={2}
            columnGap={1}
          >
            <Box spaceBelow={[0, null, null, 1]}>
              <MetadataKeyField
                canRemoveAll={canRemoveAll}
                checkMetadataValuesForDuplicates={
                  checkMetadataValuesForDuplicates
                }
                field={field}
                hideMetadataKeySelection={hideMetadataKeySelection}
                index={index}
                metadataChecked={metadataChecked}
                customFieldsData={customFieldsData}
              />
            </Box>
            <Box spaceBelow={[1, null, null, 0, null]}>
              <MetadataValueField field={field} index={index} />
            </Box>
          </XYGrid>
          <AddAndRemoveFieldRow
            showRemoveButton={array.length >= 1}
            removeMetadata={() => removeMetadata(index)}
            showAddPairButton={index === array.length - 1 && array.length < 3}
            append={append}
          />
        </Box>
      ))}
    </MetadataFieldWrapper>
  );
};
