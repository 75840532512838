import { CustomerResource } from "@gocardless/api/dashboard/types";

export const getCustomerDisplayName = (customer?: CustomerResource): string => {
  if (customer?.given_name && customer?.family_name)
    return `${customer.given_name} ${customer.family_name}`.trim();
  if (customer?.company_name) return customer.company_name;

  return customer?.name || "";
};

export const getFormattedCompanyCustomerName = (
  customer?: CustomerResource
): string => {
  const { given_name, family_name, company_name } = customer || {};
  if (company_name && given_name)
    return `${company_name} (${given_name} ${family_name})`.trim();

  return getCustomerDisplayName(customer);
};
