import {
  ZonedDateTime,
  getLocalTimeZone,
  now,
  parseAbsoluteToLocal,
} from "@internationalized/date";

interface Division {
  amount: number;
  name: Intl.RelativeTimeFormatUnit;
}

const DIVISIONS: ReadonlyArray<Division> = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
];

const formatter = (locale: string) =>
  new Intl.RelativeTimeFormat(locale, {
    numeric: "auto",
  });

export function formatTimeAgo(dateTimeString: string, locale: string): string {
  let comparisonDate: ZonedDateTime | undefined;

  try {
    comparisonDate = parseAbsoluteToLocal(dateTimeString as unknown as string);
  } catch {
    /* empty (Invalid ISO 8601 date time string) */
  }

  if (!comparisonDate) {
    return "";
  }

  const nowDate = now(getLocalTimeZone());

  if (comparisonDate.compare(nowDate) > 0) {
    return "";
  }
  let duration =
    (comparisonDate.toDate().getTime() - nowDate.toDate().getTime()) / 1000;

  for (const division of DIVISIONS) {
    if (Math.abs(duration) < division.amount) {
      return formatter(locale).format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
  return "";
}

export const isAfter = (date: string) => {
  const nowDate = now(getLocalTimeZone());
  const comparisonDate = parseAbsoluteToLocal(date);

  return nowDate.toDate().getTime() > comparisonDate.toDate().getTime();
};
