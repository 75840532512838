import {
  Box,
  Checkbox,
  ColorPreset,
  FieldSet,
  P,
  Separator,
  Space,
  Tooltip,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface MetadataFieldWrapperProps {
  children: React.ReactNode;
  clickMetadataCheckbox: (metadataVal: boolean) => void;
  description: React.ReactNode;
  duplicateField?: string;
  hideMetadataCheckbox: boolean;
  metadataChecked: boolean;
  tooltip: React.ReactNode;
  isRestricted?: boolean;
}

export const MetadataFieldWrapper: React.FC<MetadataFieldWrapperProps> = ({
  children,
  clickMetadataCheckbox,
  description,
  duplicateField,
  hideMetadataCheckbox,
  metadataChecked,
  tooltip,
  isRestricted,
}) => {
  const { i18n } = useLingui();

  return (
    <Box
      bg={ColorPreset.BackgroundLight_03}
      borderColor={ColorPreset.BorderOnLight_03}
      borderWidth={1}
      borderRadius={1}
      gutterV={2}
      gutterH={2}
    >
      {hideMetadataCheckbox ? null : (
        <>
          <Checkbox
            data-testid="add-custom-fields"
            id="addCustomFields"
            name="addCustomFields"
            defaultChecked={metadataChecked}
            onClick={(e) => {
              clickMetadataCheckbox(e.currentTarget.checked);
            }}
            disabled={isRestricted}
          >
            <Box>
              <Trans id="open-mandate.manual-creation.add-custom-fields">
                Add custom fields
              </Trans>
              <Tooltip
                message={
                  tooltip ||
                  i18n._(
                    t({
                      id: "open-mandate.manual-creation.custom-field-tooltip",
                      message:
                        "Use these to store info unique to your organisation.",
                    })
                  )
                }
              >
                <Trans id="open-mandate.manual-creation.add-custom-fields">
                  Add custom fields
                </Trans>
              </Tooltip>
            </Box>
          </Checkbox>
        </>
      )}
      {metadataChecked ? (
        <>
          {hideMetadataCheckbox ? null : (
            <>
              <Space v={2} />
              <Separator direction="block-horizontal" length="1" />
              <Space v={2} />
            </>
          )}
          <FieldSet>
            <Box>
              <P preset={TypePreset.Body_01}>
                {description || (
                  <Trans id="open-mandate.manual-creation.metadata-explained">
                    You can add, edit and remove up to three pairs of metadata.
                    Use these to store info unique to your organisation, for
                    example, custom ID numbers.
                  </Trans>
                )}
              </P>
              {duplicateField ? (
                <Box spaceAbove={2} spaceBelow={2}>
                  <P
                    color={ColorPreset.AlertTextOnLight}
                    data-testid="duplicate-field-error"
                  >
                    <Trans id="open-mandate.manual-creation.error-duplicate-field">
                      Field {duplicateField} is used multiple times, please
                      remove duplicates.
                    </Trans>
                  </P>
                </Box>
              ) : null}
              {children}
            </Box>
          </FieldSet>
        </>
      ) : null}
    </Box>
  );
};
