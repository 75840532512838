import {
  Box,
  FontWeight,
  Glyph,
  HoverEffect,
  Icon,
  JustifyContent,
  PlainButton,
  Separator,
  Space,
  Text,
  TypeScale,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { UseFieldArrayAppend } from "react-hook-form";

import { FormWithMetadata } from "./CustomMetadataField";

interface AddAndRemoveFieldRowProps {
  append: UseFieldArrayAppend<FormWithMetadata, "metadata">;
  removeMetadata: () => void;
  showAddPairButton: boolean;
  showRemoveButton: boolean;
}

export const AddAndRemoveFieldRow: React.FC<AddAndRemoveFieldRowProps> = ({
  append,
  removeMetadata,
  showAddPairButton,
  showRemoveButton,
}) => (
  <Box justifyContent={JustifyContent.SpaceBetween}>
    {showRemoveButton ? (
      <Box spaceBelow={1}>
        <PlainButton type="button" onClick={() => removeMetadata()}>
          <Icon name={Glyph.Trash} size="12px" />
          <Space layout="inline" h={0.5} />
          <Text weight={FontWeight.Bold} size={TypeScale.Size_02}>
            <Trans id="open-mandate.manual-creation.delete-pair">
              Delete pair
            </Trans>
          </Text>
        </PlainButton>
      </Box>
    ) : (
      <Box />
    )}
    <Separator
      direction="block-horizontal"
      length="100%"
      thickness={0.5}
      align="center"
      css={{
        border: "none",
        borderTop: "1px dashed",
        backgroundColor: "unset",
      }}
    />
    {showAddPairButton ? (
      <Box
        layout="flex"
        spaceAbove={1}
        justifyContent={JustifyContent.SpaceAround}
      >
        <PlainButton
          effect={HoverEffect.TextDecoration}
          onClick={() => append({ name: "", value: "" })}
        >
          <Icon name={Glyph.Add} size="12px" />
          <Space layout="inline" h={0.25} />
          <Text weight={FontWeight.Bold}>
            <Trans id="open-mandate.manual-creation.add-pair">Add pair</Trans>
          </Text>
        </PlainButton>
      </Box>
    ) : (
      <Box />
    )}
  </Box>
);
